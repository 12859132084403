import React, { useState } from 'react';
import ReactQuill from 'react-quill'; 
import { Link } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css'; 

const NewPage = () => {
  const [postTitle, setPostTitle] = useState('');
  const [editorContent, setEditorContent] = useState('');
  const [categories, setCategories] = useState({
    uncategorized: true,
    design: true,
    development: false,
    writing: false,
    books: false,
  });
  const [newCategory, setNewCategory] = useState('');

  const handleCategoryChange = (category) => {
    setCategories((prevCategories) => ({
      ...prevCategories,
      [category]: !prevCategories[category],
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Post Title:', postTitle);
    console.log('Editor Content:', editorContent);
    console.log('Selected Categories:', categories);
  };

  const handleAddCategory = () => {
    if (newCategory) {
      setCategories((prevCategories) => ({
        ...prevCategories,
        [newCategory]: false, 
      }));
      setNewCategory(''); 
    }
  };

  const [sections, setSections] = useState([{ id: Date.now() }]); // Initial section

  const handleAddSection = () => {
    setSections([...sections, { id: Date.now() }]);
  };

  const handleRemoveSection = (id) => {
    setSections(sections.filter((section) => section.id !== id));
  };


  return (
    <div className="main-content-container container-fluid px-4">
      {/* Page Header */}
      <div className="page-header row no-gutters py-4">
        <div className="col-12 col-sm-4 text-center text-sm-left mb-0">
          <span className="text-uppercase page-subtitle">New Page</span>
          {/* <h3 className="page-title">Business or Social</h3> */}
        </div>
      </div>

      <div className="row">
        <div className="col-lg-9 col-md-12">
          {/* Add New Post Form */}
          <div className="card card-small mb-3">
      <div className="card-body">

        <form className="add-new-post" onSubmit={handleSubmit}>

                          <div className='business-input-sect'>
              <div className="page-header row no-gutters py-4">
            <div className="col-12  text-center text-sm-left mb-0">
            <h3 className="page-title">New Page</h3>
            </div>
            </div>

            <select className="custom-select form-control form-control-lg custom-select-sm mb-3">
                  <option selected>Model</option>
                  <option value="1">Drop box</option>
                  <option value="2">Drop box</option>
                  <option value="3">Drop box</option>
                </select>


              <input
                  className="form-control form-control-lg mb-3"
                  type="text"
                  placeholder="Name"
                />
          </div>


          <select className="custom-select form-control form-control-lg custom-select-sm mb-3">
                  <option selected>Language</option>
                  <option value="1">Drop box</option>
                  <option value="2">Drop box</option>
                  <option value="3">Drop box</option>
                </select>

                <select className="custom-select form-control form-control-lg custom-select-sm mb-3">
                  <option selected>Personality</option>
                  <option value="1">Drop box</option>
                  <option value="2">Drop box</option>
                  <option value="3">Drop box</option>
                </select>



          <button type="submit" className="btn btn-accent puahh_btn">
            Publish
          </button>
        </form>
      </div>
    </div>
          {/* / Add New Post Form */}
        </div>

        <div className="col-lg-3 col-md-12">
          {/* Post Overview */}
          <div className='card card-small mb-3'>
            <div className="card-header border-bottom">
              <h6 className="m-0">Actions</h6>
            </div>
            <div className='card-body p-0'>
              <ul className="list-group list-group-flush">
                <li className="list-group-item p-3">
                  <span className="d-flex mb-2">
                    <i className="material-icons mr-1">flag</i>
                    <strong className="mr-1">Status:</strong> Draft
                    <Link className="ml-auto" to="#">Edit</Link>
                  </span>
                  <span className="d-flex mb-2">
                    <i className="material-icons mr-1">visibility</i>
                    <strong className="mr-1">Visibility:</strong>
                    <strong className="text-success">Public</strong>
                    <Link className="ml-auto" to="#">Edit</Link>
                  </span>
                  <span className="d-flex mb-2">
                    <i className="material-icons mr-1">calendar_today</i>
                    <strong className="mr-1">Schedule:</strong> Now
                    <Link className="ml-auto" to="#">Edit</Link>
                  </span>
                  <span className="d-flex">
                    <i className="material-icons mr-1">score</i>
                    <strong className="mr-1">Readability:</strong>
                    <strong className="text-warning">Ok</strong>
                  </span>
                </li>
                <li className="list-group-item d-flex px-3">
                  <button className="btn btn-sm btn-outline-accent">
                    <i className="material-icons">save</i> Save Draft
                  </button>
                  <button className="btn btn-sm btn-accent ml-auto">
                    <i className="material-icons">file_copy</i> Publish
                  </button>
                </li>
              </ul>
            </div>
          </div>
          {/* / Post Overview */}

          {/* Categories Overview */}
          <div className='card card-small mb-3'>
            <div className="card-header border-bottom">
              <h6 className="m-0">Categories</h6>
            </div>
            <div className='card-body p-0'>
              <ul className="list-group list-group-flush">
                {Object.keys(categories).map((category) => (
                  <li className="list-group-item px-3 pb-2" key={category}>
                    <div className="custom-control custom-checkbox mb-1">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id={category}
                        checked={categories[category]}
                        onChange={() => handleCategoryChange(category)}
                      />
                      <label className="custom-control-label" htmlFor={category}>
                        {category.charAt(0).toUpperCase() + category.slice(1)}
                      </label>
                    </div>
                  </li>
                ))}
                <li className="list-group-item d-flex px-3">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="New category"
                      aria-label="Add new category"
                      value={newCategory}
                      onChange={(e) => setNewCategory(e.target.value)}
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-white px-2"
                        type="button"
                        onClick={handleAddCategory}
                      >
                        <i className="material-icons">add</i>
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          {/* / Categories Overview */}
        </div>
      </div>
    </div>
  );
};

export default NewPage;
