import React, { useState } from 'react';
import './imported-style.css';
import './main-style.css';
import './App.css';
import Sidebar from '../src/ClientApp/components/Sidebar';
import Navbar from '../src/ClientApp/components/Navbar';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import HomePage from '../src/ClientApp/pages/userHome/HomePage';
import TablesPage from '../src/ClientApp/pages/userHome/TablesPage';
import AddBusinessContainer from './ClientApp/pages/userHome/AddBusiness/AddBusinessContainer';
import ErrorPage from '../src/ClientApp/pages/userHome/ErrorPage';
import UserProfilePage from '../src/ClientApp/pages/userHome/UserProfilePage';
import BlogPostPage from '../src/ClientApp/pages/userHome/BlogPostPage';
import NewPage from '../src/ClientApp/pages/userHome/NewPage';
import LoginContainer from '../src/ClientApp/pages/login/LoginContainer';
import Footer from '../src/ClientApp/components/Footer';

function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false); 

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen); 
  };

  const Layout = () => {
    const location = useLocation();

    if (location.pathname === '/') {
      return <LoginContainer />;
    }

    return (
      <div className="App">
        <div className="container-fluid">
          <div className="row">
            <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
            <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
              <Navbar toggleSidebar={toggleSidebar} /> 
              <Routes>
                <Route path="/user-home" element={<HomePage />} />
                <Route path="/tables" element={<TablesPage />} />
                <Route path="/add-business" element={<AddBusinessContainer />} />
                <Route path="/errors" element={<ErrorPage />} />
                <Route path="/user-profile" element={<UserProfilePage />} />
                <Route path="/blog-posts" element={<BlogPostPage />} />
                <Route path="/new-page" element={<NewPage />} />
              </Routes>
              <Footer />
            </main>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Router>
      <Routes>
        <Route path="/*" element={<Layout />} />
      </Routes>
    </Router>
  );
}

export default App;
