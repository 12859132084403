import React from 'react';
import SmallStatsChart from '../../components/SmallStatsChart';

import { Link } from 'react-router-dom';

import AvatarImg1 from '../../components/Img/avatars-1.jpg';
import AvatarImg2 from '../../components/Img/avatars-2.jpg';
import AvatarImg3 from '../../components/Img/avatars-3.jpg';

import UserStats from '../../components/UserStats';

const HomePage = () => {
    // Datasets for the charts
    const statsData = [
      {
        label: "Posts",
        value: "2,390",
        percentage: "4.7%",
        chartData: [1, 2, 1, 3, 5, 4, 7],
        backgroundColor: 'rgba(0, 184, 216, 0.1)',
        borderColor: 'rgb(0, 184, 216)',
        colorClass: 'text-info',
      },
      {
        label: "Pages",
        value: "182",
        percentage: "12.4%",
        chartData: [1, 2, 3, 3, 3, 4, 4],
        backgroundColor: 'rgba(23,198,113,0.1)',
        borderColor: 'rgb(23,198,113)',
        colorClass: 'text-success',
      },
      {
        label: "Comments",
        value: "8,147",
        percentage: "3.8%",
        chartData: [2, 3, 3, 3, 4, 3, 3],
        backgroundColor: 'rgba(255,180,0,0.1)',
        borderColor: 'rgb(255,180,0)',
        colorClass: 'text-warning',
      },
      {
        label: "Users",
        value: "2,413",
        percentage: "12.4%",
        chartData: [1, 7, 1, 3, 1, 4, 8],
        backgroundColor: 'rgba(255,65,105,0.1)',
        borderColor: 'rgb(255,65,105)',
        colorClass: 'text-danger',
      },
      {
        label: "Subscribers",
        value: "17,281",
        percentage: "2.4%",
        chartData: [3, 2, 3, 2, 4, 5, 4],
        backgroundColor: 'rgb(0,123,255,0.1)',
        borderColor: 'rgb(0,123,255)',
        colorClass: 'text-primary',
      },
    ];




    const discussions = [
        {
          avatar: AvatarImg1,
          user: 'James Johnson',
          postTitle: 'Hello World!',
          postDate: '3 days ago',
          postContent: 'Well, the way they make shows is, they make one show ...',
        },
        {
          avatar: AvatarImg2,
          user: 'James Johnson',
          postTitle: 'Hello World!',
          postDate: '4 days ago',
          postContent: 'After the avalanche, it took us a week to climb out. Now...',
        },
        {
          avatar: AvatarImg3,
          user: 'James Johnson',
          postTitle: 'Hello World!',
          postDate: '5 days ago',
          postContent: 'My money\'s in that office, right? If she start giving me...',
        },
      ];

      

  
    return (
      <div className="main-content-container container-fluid px-4">
        {/* Page Header */}
        <div className="page-header row no-gutters py-4">
          <div className="col-12 col-sm-4 text-center text-sm-left mb-0">
            <span className="text-uppercase page-subtitle">Dashboard</span>
            <h3 className="page-title">Blog Overview</h3>
          </div>
        </div>
        
        {/* Statistics Cards */}
        <div className="row">
          {statsData.map((stat, index) => (
            <div className="col-lg col-md-6 col-sm-6 mb-4" key={index}>
              <div className="stats-small stats-small--1 card card-small">
                <div className="card-body p-0 d-flex">
                  <div className="d-flex flex-column m-auto">
                    <div className="stats-small__data text-center">
                      <span className="stats-small__label text-uppercase">{stat.label}</span>
                      <h6 className="stats-small__value count my-3">{stat.value}</h6>
                    </div>
                    <div className="stats-small__data">
                      <span className={`stats-small__percentage ${stat.colorClass}`}>
                        {stat.percentage}
                      </span>
                    </div>
                  </div>
                  <SmallStatsChart
                    data={stat.chartData}
                    backgroundColor={stat.backgroundColor}
                    borderColor={stat.borderColor}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>

        <UserStats />

        <div className="row">
      {/* New Draft Component */}
      <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
        {/* Quick Post */}
        <div className="card card-small h-100">
          <div className="card-header border-bottom">
            <h6 className="m-0">New Draft</h6>
          </div>
          <div className="card-body d-flex flex-column">
            <form className="quick-post-form">
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Brave New World"
                />
              </div>
              <div className="form-group">
                <textarea
                  className="form-control"
                  placeholder="Words can be like X-rays if you use them properly..."
                ></textarea>
              </div>
              <div className="form-group mb-0">
                <button type="submit" className="btn btn-accent">Create Draft</button>
              </div>
            </form>
          </div>
        </div>
        {/* End Quick Post */}
      </div>
      {/* End New Draft Component */}

      {/* Discussions Component */}
      <div className="col-lg-5 col-md-12 col-sm-12 mb-4">
        <div className="card card-small blog-comments">
          <div className="card-header border-bottom">
            <h6 className="m-0">Discussions</h6>
          </div>
          <div className="card-body p-0">
            {discussions.map((discussion, index) => (
              <div className="blog-comments__item d-flex p-3" key={index}>
                <div className="blog-comments__avatar mr-3">
                  <img src={discussion.avatar} alt="User avatar" />
                </div>
                <div className="blog-comments__content">
                  <div className="blog-comments__meta text-muted">
                    <Link className="text-secondary" to="#">{discussion.user}</Link > on
                    <Link  className="text-secondary" to="#">{discussion.postTitle}</Link >
                    <span className="text-muted">– {discussion.postDate}</span>
                  </div>
                  <p className="m-0 my-1 mb-2 text-muted">{discussion.postContent}</p>
                  <div className="blog-comments__actions">
                    <div className="btn-group btn-group-sm">
                      <button type="button" className="btn btn-white">
                        <span className="text-success">
                          <i className="material-icons">check</i>
                        </span> Approve
                      </button>
                      <button type="button" className="btn btn-white">
                        <span className="text-danger">
                          <i className="material-icons">clear</i>
                        </span> Reject
                      </button>
                      <button type="button" className="btn btn-white">
                        <span className="text-light">
                          <i className="material-icons">more_vert</i>
                        </span> Edit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="card-footer border-top">
            <div className="row">
              <div className="col text-center view-report">
                <button type="button" className="btn btn-white">View All Comments</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Discussions Component */}

      {/* Top Referrals Component */}
      <div className="col-lg-3 col-md-12 col-sm-12 mb-4">
        <div className="card card-small">
          <div className="card-header border-bottom">
            <h6 className="m-0">Top Referrals</h6>
          </div>
          <div className="card-body p-0">
            <ul className="list-group list-group-small list-group-flush">
              {[
                { name: 'GitHub', count: 19291 },
                { name: 'Stack Overflow', count: 11201 },
                { name: 'Hacker News', count: 9291 },
                { name: 'Reddit', count: 8281 },
                { name: 'The Next Web', count: 7128 },
                { name: 'Tech Crunch', count: 6218 },
                { name: 'YouTube', count: 1218 },
                { name: 'Adobe', count: 827 },
              ].map((item, index) => (
                <li className="list-group-item d-flex px-3" key={index}>
                  <span className="text-semibold text-fiord-blue">{item.name}</span>
                  <span className="ml-auto text-right text-semibold text-reagent-gray">{item.count}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className="card-footer border-top">
            <div className="row">
              <div className="col">
                <select className="custom-select custom-select-sm">
                  <option selected>Last Week</option>
                  <option value="1">Today</option>
                  <option value="2">Last Month</option>
                  <option value="3">Last Year</option>
                </select>
              </div>
              <div className="col text-right view-report">
                <Link to="#">Full report &rarr;</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Top Referrals Component */}
    </div>



      </div>
    );
};

export default HomePage;
