import React, { useRef } from 'react';
import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

const SmallStatsChart = ({ data, backgroundColor, borderColor }) => {
  const chartRef = useRef(null);

  const chartData = {
    labels: ["Label 1", "Label 2", "Label 3", "Label 4", "Label 5", "Label 6", "Label 7"],
    datasets: [
      {
        label: [], 
        fill: 'start',
        data,
        backgroundColor,
        borderColor,
        borderWidth: 1.5,
      }
    ]
  };

  const chartOptions = {
    maintainAspectRatio: true,
    responsive: true,
    plugins: {
      legend: {
        display: false, 
      },
      tooltip: {
        enabled: false, 
      },
    },
    elements: {
      point: {
        radius: 0, 
      },
      line: {
        tension: 0.3, 
      }
    },
    scales: {
      x: {
        grid: {
          display: false, 
        },
        ticks: {
          display: false, 
        }
      },
      y: {
        grid: {
          display: false, 
        },
        ticks: {
          display: false, 
          suggestedMax: Math.max(...data) + 1 
        }
      }
    }
  };

  return (
    <Line ref={chartRef} data={chartData} options={chartOptions} />
  );
};

export default SmallStatsChart;
