import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import UserAvatar from './Img/avatars-0.jpg'; 

const Navbar = ({ toggleSidebar }) => {
  const [notifications] = useState([
    {
      category: 'Analytics',
      message: "Your website’s active users count increased by <span class='text-success text-semibold'>28%</span> in the last week. Great job!",
      icon: "&#xE6E1;",
    },
    {
      category: 'Sales',
      message: "Last week your store’s sales count decreased by <span class='text-danger text-semibold'>5.52%</span>. It could have been worse!",
      icon: "&#xE8D1;",
    },
  ]);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Function to toggle dropdown state
  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  // Effect to close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="main-navbar sticky-top gaga">
      <nav className="navbar align-items-stretch navbar-light flex-md-nowrap p-0">
        <form action="#" className="main-navbar__search w-100 d-none d-md-flex d-lg-flex">
          <div className="input-group input-group-seamless ml-3">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <i className="fas fa-search"></i>
              </div>
            </div>
            <input
              className="navbar-search form-control"
              type="text"
              placeholder="Search for something..."
              aria-label="Search"
            />
          </div>
        </form>
        <ul className="navbar-nav flex-row">
          <li className="nav-item  dropdown notifications" ref={dropdownRef}>
            <Link
              className="nav-link nav-link-icon text-center"
              to="#"
              role="button"
              onClick={toggleDropdown}
              aria-haspopup="true"
              aria-expanded={dropdownOpen ? 'true' : 'false'}
            >
              <div className="nav-link-icon__wrapper">
                <i className="material-icons">&#xE7F4;</i>
                <span className="badge badge-pill badge-danger">{notifications.length}</span>
              </div>
            </Link>
            {dropdownOpen && (
              <div className="dropdown-menu dropdown-menu-small show" aria-labelledby="dropdownMenuLink">
                {notifications.map((notification, index) => (
                  <Link className="dropdown-item" to="#" key={index}>
                    <div className="notification__icon-wrapper">
                      <div className="notification__icon">
                        <i className="material-icons" dangerouslySetInnerHTML={{ __html: notification.icon }} />
                      </div>
                    </div>
                    <div className="notification__content">
                      <span className="notification__category">{notification.category}</span>
                      <p dangerouslySetInnerHTML={{ __html: notification.message }} />
                    </div>
                  </Link>
                ))}
                <Link className="dropdown-item notification__all text-center" to="#">
                  View all Notifications
                </Link>
              </div>
            )}
          </li>
          <li className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle text-nowrap px-3"
              data-toggle="dropdown"
              to="#"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img className="user-avatar rounded-circle mr-2" src={UserAvatar} alt="User Avatar" />
              <span className="d-none d-md-inline-block">User</span>
            </Link>
            <div className="dropdown-menu dropdown-menu-small">
              <Link className="dropdown-item" to="user-profile-lite.html">
                <i className="material-icons">&#xE7FD;</i> Profile
              </Link>
              <Link className="dropdown-item" to="add-new-post.html">
                <i className="material-icons">note_add</i> Add Business
              </Link>
              <Link className="dropdown-item" to="#">
                <i className="material-icons">vertical_split</i> Default/Dark Theme
              </Link>
              <div className="dropdown-divider"></div>
              <Link className="dropdown-item text-danger" to="#">
                <i className="material-icons text-danger">&#xE879;</i> Logout
              </Link>
            </div>
          </li>
        </ul>
        <nav className="nav Pg_Togller">
          <Link
            to="#"
            className="nav-link nav-link-icon toggle-sidebar d-md-inline d-lg-none text-center"
            role="button"
            onClick={toggleSidebar}
          >
            <i className="material-icons">&#xE5D2;</i>
          </Link>
        </nav>
      </nav>
    </div>
  );
};

export default Navbar;
