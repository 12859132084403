import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Chart from 'chart.js/auto'; // Ensure you have Chart.js installed
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS for the date picker

const UserStats = () => {
  const usersChartRef = useRef(null);
  const deviceChartRef = useRef(null);

  // State for date selection
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    // Data for Users Stats Chart
    const bouData = {
      labels: Array.from(new Array(30), (_, i) => (i === 0 ? 1 : i)),
      datasets: [
        {
          label: 'Current Month',
          fill: 'start',
          data: [500, 800, 320, 180, 240, 320, 230, 650, 590, 1200, 750, 940, 1420, 1200, 960, 1450, 1820, 2800, 2102, 1920, 3920, 3202, 3140, 2800, 3200, 3200, 3400, 2910, 3100, 4250],
          backgroundColor: 'rgba(0,123,255,0.1)',
          borderColor: 'rgba(0,123,255,1)',
          pointBackgroundColor: '#ffffff',
          pointHoverBackgroundColor: 'rgb(0,123,255)',
          borderWidth: 1.5,
          pointRadius: 0,
          pointHoverRadius: 3,
        },
        {
          label: 'Past Month',
          fill: 'start',
          data: [380, 430, 120, 230, 410, 740, 472, 219, 391, 229, 400, 203, 301, 380, 291, 620, 700, 300, 630, 402, 320, 380, 289, 410, 300, 530, 630, 720, 780, 1200],
          backgroundColor: 'rgba(255,65,105,0.1)',
          borderColor: 'rgba(255,65,105,1)',
          pointBackgroundColor: '#ffffff',
          pointHoverBackgroundColor: 'rgba(255,65,105,1)',
          borderDash: [3, 3],
          borderWidth: 1,
          pointRadius: 0,
          pointHoverRadius: 2,
          pointBorderColor: 'rgba(255,65,105,1)',
        },
      ],
    };

    // Options for Users Stats Chart
    const bouOptions = {
      responsive: true,
      legend: {
        position: 'top',
      },
      elements: {
        line: {
          tension: 0.3,
        },
        point: {
          radius: 0,
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            callback: (tick, index) => (index % 7 !== 0 ? '' : tick),
          },
        },
        y: {
          ticks: {
            suggestedMax: 45,
            callback: (tick) => (tick > 999 ? (tick / 1000).toFixed(1) + 'K' : tick),
          },
        },
      },
      hover: {
        mode: 'nearest',
        intersect: false,
      },
      tooltips: {
        custom: false,
        mode: 'nearest',
        intersect: false,
      },
    };

    // Generate the Analytics Overview chart.
    const usersChart = new Chart(usersChartRef.current, {
      type: 'line',
      data: bouData,
      options: bouOptions,
    });

    // Hide initially the first and last analytics overview chart points.
    const aocMeta = usersChart.getDatasetMeta(0);
    aocMeta.data[0].hidden = true; // Hide first point
    aocMeta.data[bouData.datasets[0].data.length - 1].hidden = true; // Hide last point
    usersChart.update(); // Update the chart to reflect changes

    // Data for Users By Device Pie Chart
    const ubdData = {
      datasets: [
        {
          hoverBorderColor: '#ffffff',
          data: [68.3, 24.2, 7.5],
          backgroundColor: [
            'rgba(0,123,255,0.9)',
            'rgba(0,123,255,0.5)',
            'rgba(0,123,255,0.3)',
          ],
        },
      ],
      labels: ['Desktop', 'Tablet', 'Mobile'],
    };

    // Options for Users By Device Chart
    const ubdOptions = {
      legend: {
        position: 'bottom',
        labels: {
          padding: 25,
          boxWidth: 20,
        },
      },
      cutoutPercentage: 0,
      tooltips: {
        custom: false,
        mode: 'index',
        position: 'nearest',
      },
    };

    // Generate the users by device chart.
    const deviceChart = new Chart(deviceChartRef.current, {
      type: 'pie',
      data: ubdData,
      options: ubdOptions,
    });

    // Clean up charts on component unmount
    return () => {
      usersChart.destroy();
      deviceChart.destroy();
    };
  }, []);

  return (
    <div className="row">
      {/* Users Stats */}
      <div className="col-lg-8 col-md-12 col-sm-12 mb-4">
        <div className="card card-small">
          <div className="card-header border-bottom">
            <h6 className="m-0">Users</h6>
          </div>
          <div className="card-body pt-0">
            <div className="row border-bottom py-2">
              <div className="col-12 col-sm-6">
                <div
                  id="blog-overview-date-range"
                  className="input-daterange input-group input-group-sm my-auto ml-auto mr-auto ml-sm-auto mr-sm-0"
                  style={{ maxWidth: '350px' }}
                >
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    className="input-sm form-control"
                    placeholderText="Start Date"
                    dateFormat="MM/dd/yyyy"
                  />
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    className="input-sm form-control"
                    placeholderText="End Date"
                    dateFormat="MM/dd/yyyy"
                  />
                  <span className="input-group-append">
                    <span className="input-group-text">
                      <i className="material-icons"></i>
                    </span>
                  </span>
                </div>
              </div>
              <div className="col-12 col-sm-6 d-flex mb-2 mb-sm-0">
                <button type="button" className="btn btn-sm btn-white ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0">
                  View Full Report &rarr;
                </button>
              </div>
            </div>
            <canvas ref={usersChartRef} height="130" style={{ maxWidth: '100% !important' }}></canvas>
          </div>
        </div>
      </div>
      {/* End Users Stats */}
      {/* Users By Device Stats */}
      <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
        <div className="card card-small h-100">
          <div className="card-header border-bottom">
            <h6 className="m-0">Users by device</h6>
          </div>
          <div className="card-body d-flex py-0">
            <canvas ref={deviceChartRef} height="220" className="blog-users-by-device m-auto"></canvas>
          </div>
          <div className="card-footer border-top">
            <div className="row">
              <div className="col">
                <select className="custom-select custom-select-sm" style={{ maxWidth: '130px' }}>
                  <option selected>Last Week</option>
                  <option value="1">Today</option>
                  <option value="2">Last Month</option>
                  <option value="3">Last Year</option>
                </select>
              </div>
              <div className="col text-right view-report">
                <Link to="#">Full report &rarr;</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Users By Device Stats */}
    </div>
  );
};

export default UserStats;
