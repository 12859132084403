import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SiteLogo from './Img/site-logo.png';

const Sidebar = ({ sidebarOpen, toggleSidebar }) => {
  // State to manage the active link
  const [activeLink, setActiveLink] = useState('/'); // Default active link

  // Effect to load active link from localStorage on mount
  useEffect(() => {
    const storedActiveLink = localStorage.getItem('activeLink');
    if (storedActiveLink) {
      setActiveLink(storedActiveLink);
    }
  }, []);

  // Function to handle link click
  const handleLinkClick = (link) => {
    setActiveLink(link);
    localStorage.setItem('activeLink', link); // Save active link to localStorage
    toggleSidebar(); // Close the sidebar when a link is clicked
  };

  return (
    <aside className={`main-sidebar col-12 col-md-3 col-lg-2 px-0 ${sidebarOpen ? 'open' : ''}`}>
      <div className="main-navbar">
        <nav className="navbar align-items-stretch navbar-light bg-white flex-md-nowrap p-0">
          <Link className="navbar-brand w-100" to="/" style={{ lineHeight: '25px' }}>
            <div className="d-table m-auto">
              <img
                id="main-logo"
                className="d-inline-block align-top mr-1"
                style={{ maxWidth: '25px' }}
                src={SiteLogo}
                alt="Site Logo"
              />
              <span className="d-none d-md-inline ml-1">User Dashboard</span>
            </div>
          </Link>
          <a className="toggle-sidebar d-sm-inline d-md-none d-lg-none" onClick={toggleSidebar}>
            <i className="material-icons">&#xE5C4;</i>
          </a>
        </nav>
      </div>
      <form className="main-sidebar__search w-100 border-right d-sm-flex d-md-none d-lg-none">
        <div className="input-group input-group-seamless ml-3">
          <div className="input-group-prepend">
            <div className="input-group-text">
              <i className="fas fa-search"></i>
            </div>
          </div>
          <input
            className="navbar-search form-control"
            type="text"
            placeholder="Search for something..."
            aria-label="Search"
          />
        </div>
      </form>
      <div className="nav-wrapper">
        <ul className="nav flex-column">
          <li className="nav-item">
            <Link
              className={`nav-link ${activeLink === '/' ? 'active' : ''}`}
              to="/"
              onClick={() => handleLinkClick('/')}
            >
              <i className="material-icons">edit</i>
              <span>Business Dashboard</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${activeLink === '/edit-agents' ? 'active' : ''}`}
              to="/edit-agents"
              onClick={() => handleLinkClick('/add-new-post')}
            >
              <i className="material-icons">note_add</i>
              <span>Agents</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${activeLink === '/add-business' ? 'active' : ''}`}
              to="/add-business"
              onClick={() => handleLinkClick('/add-business')}
            >
              <i className="material-icons">note_add</i>
              <span>Business</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link
              className={`nav-link ${activeLink === '/new-page' ? 'active' : ''}`}
              to="/new-page"
              onClick={() => handleLinkClick('/new-page')}
            >
              <i className="material-icons">note_add</i>
              <span>New Page</span>
            </Link>
          </li>


          <li className="nav-item">
            <Link
              className={`nav-link ${activeLink === '/blog-posts' ? 'active' : ''}`}
              to="/blog-posts"
              onClick={() => handleLinkClick('/blog-posts')}
            >
              <i className="material-icons">vertical_split</i>
              <span>Blog Posts</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${activeLink === '/tables' ? 'active' : ''}`}
              to="/tables"
              onClick={() => handleLinkClick('/tables')}
            >
              <i className="material-icons">table_chart</i>
              <span>Tables</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${activeLink === '/user-profile' ? 'active' : ''}`}
              to="/user-profile"
              onClick={() => handleLinkClick('/user-profile')}
            >
              <i className="material-icons">person</i>
              <span>User Profile</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${activeLink === '/errors' ? 'active' : ''}`}
              to="/errors"
              onClick={() => handleLinkClick('/errors')}
            >
              <i className="material-icons">error</i>
              <span>Errors</span>
            </Link>
          </li>
        </ul>
      </div>
    </aside>
  );
};

export default Sidebar;
