import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';

const AddBusiness = () => {
  const [postTitle, setPostTitle] = useState('');
  const [editorContent, setEditorContent] = useState('');
  const [categories, setCategories] = useState({
    uncategorized: true,
    design: true,
    development: false,
    writing: false,
    books: false,
  });
  const [newCategory, setNewCategory] = useState('');
  const [activeTab, setActiveTab] = useState('addBusiness');

  const handleCategoryChange = (category) => {
    setCategories((prevCategories) => ({
      ...prevCategories,
      [category]: !prevCategories[category],
    }));
  };

  const handleAddCategory = () => {
    if (newCategory) {
      setCategories((prevCategories) => ({
        ...prevCategories,
        [newCategory]: false,
      }));
      setNewCategory('');
    }
  };

  const [sections, setSections] = useState([{ id: Date.now(), businessAddress: '', businessName: '', accountName: '', phoneNumber: '', google: false, yelp: false, socialAccounts: [{ id: Date.now(), accountName: '' }] }]);

  const handleAddSection = () => {
    setSections([...sections, { id: Date.now(), businessAddress: '', businessName: '', accountName: '', phoneNumber: '', google: false, yelp: false, socialAccounts: [{ id: Date.now(), accountName: '' }] }]);
  };

  const handleRemoveSection = (id) => {
    setSections(sections.filter((section) => section.id !== id));
  };

  const handleAddSocialAccount = (sectionId) => {
    setSections((prevSections) =>
      prevSections.map((section) =>
        section.id === sectionId
          ? {
              ...section,
              socialAccounts: [...section.socialAccounts, { id: Date.now(), accountName: '' }],
            }
          : section
      )
    );
  };

  const getSocialAccounts = (records) => {
    return records.map(record => {
        return {
            id: record.id,
            socialAccountCount: record.socialAccounts.length
        };
    });
  };

  const handleRemoveSocialAccount = (sectionId, accountId) => {
    const socialSections = getSocialAccounts(sections);

    if (socialSections &&
      socialSections.find(record => record.id === sectionId)?.socialAccountCount === 1) {
        return;
    }

    setSections((prevSections) =>
      prevSections.map((section) =>
        section.id === sectionId
          ? {
              ...section,
              socialAccounts: section.socialAccounts.filter((account) => account.id !== accountId),
            }
          : section
      )
    );
  };

  const handleResetForm = () => {
    setPostTitle('');
    setEditorContent('');
    setCategories({
      uncategorized: true,
      design: true,
      development: false,
      writing: false,
      books: false,
    });
    setSections([{ id: Date.now(), businessAddress: '', businessName: '', accountName: '', phoneNumber: '', google: false, yelp: false, socialAccounts: [{ id: Date.now(), accountName: '' }] }]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Post Title:', postTitle);
    console.log('Editor Content:', editorContent);
    console.log('Selected Categories:', categories);

    sections.forEach((section) => {
      console.log('Business Address:', section.businessAddress);
      console.log('Business Name:', section.businessName);
      console.log('Account Name:', section.accountName);
      console.log('Phone Number:', section.phoneNumber);
      console.log('Google:', section.google);
      console.log('Yelp:', section.yelp);
      section.socialAccounts.forEach((account) => {
        console.log('Social Account Name:', account.accountName);
      });
    });
    handleResetForm();
  };

  return (
    <div className="main-content-container container-fluid px-4">
      {/* Page Header */}
      <div className="page-header row no-gutters py-4">
        <div className="col-12 col-sm-4 text-center text-sm-left mb-0">
          <span className="text-uppercase page-subtitle">Business</span>
        </div>
      </div>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={activeTab}
          onChange={(e, newValue) => setActiveTab(newValue)}
          aria-label="business tabs"
          className="business-tabs"
          TabIndicatorProps={{ style: { backgroundColor: '#9b6008' } }}
        >
          <Tab value="addBusiness" label="Add Business" className={activeTab === 'addBusiness' ? 'active-tab' : ''} />
          <Tab value="viewBusiness" label="View Business" className={activeTab === 'viewBusiness' ? 'active-tab' : ''} />
        </Tabs>
      </Box>

      {activeTab === 'addBusiness' && (
        <div className="row">
          <div className="col-lg-9 col-md-12">
            {/* Add New Post Form */}
            <div className="card card-small mb-3">
              <div className="card-body">
                <div className="page-header row no-gutters py-4">
                  <div className="col-12 text-center text-sm-left mb-0 oooa-flex oooa-flex-OO">
                    <h3 className="add-business-title">New Business</h3>
                  </div>
                </div>
                <form className="add-new-post" onSubmit={handleSubmit}>
                  {sections.map((section, index) => (
                    <React.Fragment key={section.id}>
                      <div className="business-input-sect">
                        <div className="page-header row no-gutters py-4">
                          <div className="col-12 text-center text-sm-left mb-0 oooa-flex">
                            <div className="remove-busines-panel">
                              {index === 0 ? '' : <hr className="business-divider" />}
                              <h3 className="add-business-title">
                                {index === 0 ? '' : 'New Business'}
                              </h3>
                              {index > 0 && (
                                <button
                                  type="button"
                                  className="btn remove-busness-submit-button"
                                  onClick={() => handleRemoveSection(section.id)}
                                >
                                  <i className="material-icons">remove</i>
                                  Remove
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="checkbox-container mb-3 d-flex align-items-center">
                          <div className="form-check form-check-inline">
                            <span className="add-business-listed-on">
                              Business on: 
                            </span>
                            <input
                              className="form-check-input add-business-checkboxes"
                              type="checkbox"
                              id={`googleCheckbox-${section.id}`}
                              checked={section.google}
                              onChange={(e) => setSections((prevSections) =>
                                prevSections.map((s) =>
                                  s.id === section.id ? { ...s, google: e.target.checked } : s
                                ))
                              }
                            />
                            <label className="form-check-label" htmlFor={`googleCheckbox-${section.id}`}>Google</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input add-business-checkboxes"
                              type="checkbox"
                              id={`yelpCheckbox-${section.id}`}
                              checked={section.yelp}
                              onChange={(e) => setSections((prevSections) =>
                                prevSections.map((s) =>
                                  s.id === section.id ? { ...s, yelp: e.target.checked } : s
                                ))
                              }
                            />
                            <label className="form-check-label" htmlFor={`yelpCheckbox-${section.id}`}>Yelp</label>
                          </div>
                        </div>
                        <Collapse in={section.google || section.yelp} timeout="auto" unmountOnExit>
                          <div className="listed-business-details">
                            <input
                              className="form-control form-control-lg mb-3"
                              type="text"
                              placeholder="Business Address"
                              value={section.businessAddress}
                              onChange={(e) => setSections((prevSections) =>
                                prevSections.map((s) =>
                                  s.id === section.id ? { ...s, businessAddress: e.target.value } : s
                                ))
                              }
                            />
                            <input
                              className="form-control form-control-lg mb-3"
                              type="text"
                              placeholder="Business name"
                              value={section.businessName}
                              onChange={(e) => setSections((prevSections) =>
                                prevSections.map((s) =>
                                  s.id === section.id ? { ...s, businessName: e.target.value } : s
                                ))
                              }
                            />
                            <div className="OG_FLEX">
                              <input
                                className="form-control form-control-lg mb-3"
                                type="text"
                                placeholder="Account name"
                                value={section.accountName}
                                onChange={(e) => setSections((prevSections) =>
                                  prevSections.map((s) =>
                                    s.id === section.id ? { ...s, accountName: e.target.value } : s
                                  ))
                                }
                              />
                              <input
                                className="form-control form-control-lg mb-3"
                                type="text"
                                placeholder="Phone number"
                                value={section.phoneNumber}
                                onChange={(e) => setSections((prevSections) =>
                                  prevSections.map((s) =>
                                    s.id === section.id ? { ...s, phoneNumber: e.target.value } : s
                                  ))
                                }
                              />
                            </div>
                          </div>
                        </Collapse>
                      </div>
                      <div className="business-input-sect">
                        <div className="page-header row no-gutters py-4">
                          <div className="col-12 text-center text-sm-left mb-0 oooa-flex">
                            <h3 className="add-business-title">Socials</h3>
                          </div>
                        </div>
                        <div className="social-section">
                        {section.socialAccounts.map((account, accountIndex) => (
                          <div key={account.id} className="d-flex align-items-center mb-3">
                            <input
                              className="form-control form-control-lg"
                              type="text"
                              placeholder="Account Link"
                              value={account.accountName}
                              onChange={(e) => setSections((prevSections) =>
                                prevSections.map((s) =>
                                  s.id === section.id
                                    ? {
                                        ...s,
                                        socialAccounts: s.socialAccounts.map((a) =>
                                          a.id === account.id ? { ...a, accountName: e.target.value } : a
                                        ),
                                      }
                                    : s
                                ))
                              }
                              style={{ flex: 1 }}
                            />
                            <div className="d-flex ml-2 add-business-checkboxes">
                              <button
                                type="button"
                                className="btn remove-social-submit-button"
                                onClick={() => handleRemoveSocialAccount(section.id, account.id)}
                              >
                                <i className="material-icons">remove</i>
                              </button>
                              <button
                                type="button"
                                className="btn puahh_btn add-social-submit-button ml-2"
                                onClick={() => handleAddSocialAccount(section.id)}
                                disabled={accountIndex !== section.socialAccounts.length - 1}
                              >
                                <i className="material-icons">add</i>
                              </button>
                            </div>
                          </div>
                        ))}

                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                  <div className="add-busines-panel">
                    <button
                      type="button"
                      className="btn add-busness-cancel-button puahh_btn"
                      onClick={() => handleResetForm()}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn add-busness-submit-button puahh_btn"
                    >
                      Save
                    </button>
                    <button
                        type="button"
                        className="btn puahh_btn add-busines-button-bottom"
                        onClick={handleAddSection}
                      >
                        <i className="material-icons">add</i> More Business?
                    </button>
                  </div>
                </form>
              </div>
            </div>
            {/* / Add New Post Form */}
          </div>
          <div className="col-lg-3 col-md-12">
          {/* Post Overview */}
          <div className="card card-small mb-3">
            <div className="card-header border-bottom">
              <h6 className="m-0">Status</h6>
            </div>
            <div className="card-body p-0">
              <ul className="list-group list-group-flush">
                <li className="list-group-item p-3">
                  <span className="d-flex mb-2">
                    <i className="material-icons mr-1">flag</i>
                    <strong className="mr-1">Model:</strong> NG default
                    <Link className="ml-auto" to="#">
                      Edit
                    </Link>
                  </span>
                  <span className="d-flex mb-2">
                    <i className="material-icons mr-1">visibility</i>
                    <strong className="mr-1">State:</strong>
                    <strong className="text-success">Active</strong>
                    <Link className="ml-auto" to="#">
                      Edit
                    </Link>
                  </span>
                  <span className="d-flex mb-2">
                    <i className="material-icons mr-1">calendar_today</i>
                    <strong className="mr-1">Scheduled:</strong>
                    <strong className="text-success">Yes</strong>
                    <Link className="ml-auto" to="#">
                      Edit
                    </Link>
                  </span>
                  <span className="d-flex">
                    <i className="material-icons mr-1">score</i>
                    <strong className="mr-1">Runs Daily:</strong>
                    <strong className="text-success">Yes</strong>
                  </span>
                </li>
                <li className="list-group-item d-flex px-3">
                  <button className="btn btn-sm add-busness-submit-button ml-auto">
                    <i className="material-icons">file_copy</i> View
                  </button>
                </li>
              </ul>
            </div>
          </div>
          {/* / Post Overview */}

          {/* Categories Overview */}
          <div className="card card-small mb-3">
            <div className="card-header border-bottom">
              <h6 className="m-0">Categories</h6>
            </div>
            <div className="card-body p-0">
              <ul className="list-group list-group-flush">
                {Object.keys(categories).map((category) => (
                  <li className="list-group-item px-3 pb-2" key={category}>
                    <div className="custom-control custom-checkbox mb-1">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id={category}
                        checked={categories[category]}
                        onChange={() => handleCategoryChange(category)}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={category}
                      >
                        {category.charAt(0).toUpperCase() + category.slice(1)}
                      </label>
                    </div>
                  </li>
                ))}
                <li className="list-group-item d-flex px-3">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="New category"
                      aria-label="Add new category"
                      value={newCategory}
                      onChange={(e) => setNewCategory(e.target.value)}
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-white px-2"
                        type="button"
                        onClick={handleAddCategory}
                      >
                        <i className="material-icons">add</i>
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          {/* / Categories Overview */}
        </div>
        </div>
      )}

      {activeTab === 'viewBusiness' && (
        <div className="view-business-section">
          {/* Placeholder for View Business Section */}
          <h3 className="view-business-title">View Businesses</h3>
          {/* Add the list or table of businesses here */}
        </div>
      )}
    </div>
  );
};

export default AddBusiness;
