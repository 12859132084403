import React, { useState } from 'react';
import './LoginContainer.css';
import { TextField, Button, Box, InputAdornment, IconButton } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import AppleIcon from '@mui/icons-material/Apple';
import GoogleIcon from '@mui/icons-material/Google';
import CloseIcon from '@mui/icons-material/Close';

const LoginContainer = () => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box className="login-page">
      <Box className="login-background">
        <Box className="login-box">
          <h2>Hi there! I'm ngozi-ai</h2>
          <p>Don't have an account yet? <a href="#">Sign up</a></p>
          <form className="login-form">
            <TextField
              className="form-control form-control-lg mb-3" 
              variant="outlined"
              label="Email Address" 
              fullWidth
              InputLabelProps={{
                style: { color: '#f0f6f7e3' },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className="text-fields-icon" >
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField 
              className="form-control form-control-lg mb-3 text-fields" 
              variant="outlined" 
              label="Password" 
              type={showPassword ? 'text' : 'password'} 
              fullWidth
              InputLabelProps={{
                style: { color: '#f0f6f7e3' },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className="text-fields-icon">
                    <LockIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility className="text-fields-icon"/> : <VisibilityOff className="text-fields-icon"/>}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button variant="outlined" className="login-button" fullWidth>Login</Button>
          </form>
          <p>OR</p>
          <Box className="icon-buttons">
            <Button startIcon={<AppleIcon />} variant="outlined" className="icon-button"></Button>
            <Button startIcon={<GoogleIcon />} variant="outlined" className="icon-button"></Button>
            <Button startIcon={<CloseIcon />} variant="outlined" className="icon-button"></Button>
          </Box>
        </Box>
        <Box className="extra-background-element"></Box>
        <Box className="extra-background-element second"></Box>
        <Box className="extra-background-element third"></Box>
        <Box className="extra-background-element fourth"></Box>
        <Box className="extra-background-element fifth"></Box>
        <Box className="extra-background-element sixth"></Box>
        <Box className="extra-background-element seventh"></Box>
      </Box>
    </Box>
  );
};

export default LoginContainer;