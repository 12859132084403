import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {

    const currentYear = new Date().getFullYear();

  return (
    <footer className="main-footer d-flex p-2 px-3 bg-white border-top">
      <ul className="nav">
        <li className="nav-item">
          <Link className="nav-link" to="/">Home</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/services">Services</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/about">About</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/products">Products</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/blog">Blog</Link>
        </li>
      </ul>
      <span className="copyright ml-auto my-auto mr-2">
     © {currentYear}
      </span>
    </footer>
  );
};

export default Footer;
